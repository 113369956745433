import React, { useState, createContext, useEffect, useRef } from 'react';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import { Button, Divider, makeStyles } from '@material-ui/core';
import 'moment/locale/pl';
import moment from 'moment';
import { sendAuthAjax, sendAuthFetch } from 'helpers/authorization';
import {
    setIsLoading,
    addAlert,
    selectShops,
} from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import GraphicPeriodDialog from 'components/GraphicPeriodDialog';
import GraphicTable from 'components/GraphicTable';
import useIsGranted from 'hooks/useIsGranted';
import { useHistory } from 'react-router-dom';
import { selectUser } from 'context/features/user/userSlice';

moment.locale('pl');

export const GraphicContext = createContext(null);

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    createSummaryDivider: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    thresholdButton: {
        marginRight: '20px',
        marginTop: '10px',
    },
}));

const Graphic = () => {
    const [periodDialogOpen, setPeriodDialogOpen] = useState(false);
    const [employeesData, setEmployeesData] = useState([]);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectEmployee, setSelectEmployee] = useState(null);
    const [selectDate, setSelectDate] = useState(null);
    const [employeeTimeSheet, setEmployeeTimeSheet] = useState(null);
    const [selectShop, setSelectShop] = useState('');
    const [update, setUpdate] = useState(null);
    const [graphicTypes, setGraphicTypes] = useState([]);
    const [showTimePickers, setShowTimePickers] = useState(false);
    const [showWarehouseTimePickers, setShowWarehouseTimePickers] = useState(
        false
    );
    const isGranted = useIsGranted();
    const editAccess = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
        text.ROLE_DIRECTOR_CODE,
    ]);
    const generateSummaryAccess = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
        text.ROLE_DIRECTOR_CODE,
    ]);
    const history = useHistory();

    const [startDate, setStartDate] = useState(moment().startOf('week'));

    const isDirector = isGranted.hasOneOfRoles.execute([
        text.ROLE_DIRECTOR_CODE,
    ]);

    const scheduleAccess = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
    ]);

    const shops = useSelector((state) => selectShops(state));
    const user = useSelector((state) => selectUser(state));
    const userShop = shops.find((shop) => shop.id === user.shopId);
    const ref = useRef(null);
    const generateAction = isDirector
        ? async () =>
              getSummaryData('/timesheet-monthly-summary', true, userShop.id)
        : () => history.push('/grafik-generuj-podsumowanie');

    const dayOfMonth = moment().date();
    const canGenerateForPreviousMonth =
        isDirector && dayOfMonth >= 1 && dayOfMonth <= 5;

    const getSummaryData = async (url, showAlert = false, shop = null) => {
        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthFetch(url);
            if (showAlert) {
                dispatch(
                    addAlert({ content: 'Poprawnie zapisano', type: 'success' })
                );
            }

            const blobby = await response.blob();
            const objectUrl = window.URL.createObjectURL(blobby);

            ref.current.href = objectUrl;
            ref.current.download = 'miesieczne_podsumowanie_grafiku.pdf';
            ref.current.click();

            window.URL.revokeObjectURL(objectUrl);
        } catch (error) {
            const errorText =
                error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const getEmployeesData = async () => {
        const dateFrom = moment(startDate).startOf('week').format('YYYY-MM-DD');
        const dateTo = moment(startDate).endOf('week').format('YYYY-MM-DD');

        const url = `/timesheets?dateFrom=${dateFrom}&dateTo=${dateTo}&shopId=${selectShop}`;

        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('get', url);
            setEmployeesData(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handlePeriodDialogOpen = (id, date = null, userTimeSheet = null) => {
        setSelectEmployee(id);
        if (date !== null) {
            setSelectDate(date);
        }

        if (userTimeSheet !== null) {
            setEmployeeTimeSheet(userTimeSheet);
        }

        setPeriodDialogOpen(true);
    };

    const handlePeriodDialogClose = () => {
        setSelectEmployee(null);
        setPeriodDialogOpen(false);
        setSelectDate(null);
        setEmployeeTimeSheet(null);
        setShowTimePickers(false);
        setShowWarehouseTimePickers(false);
    };

    const getGraphicTypes = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/dictionaries/categories/graphicTypes`
            );
            setGraphicTypes(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getEmployeesData();
    }, [startDate, update, selectShop]);

    useEffect(() => {
        getGraphicTypes();
    }, []);

    return (
        <>
            <ViewContentCard title={text.GRAPHIC}>
                {generateSummaryAccess && (
                    <>
                        <Button
                            onClick={() => history.push('/grafik-ustaw-progi')}
                            variant="contained"
                            color="primary"
                            className={classes.thresholdButton}
                        >
                            {text.SET_THRESHOLDS}
                        </Button>

                        {scheduleAccess && (
                            <Button
                                onClick={() =>
                                    history.push('/grafik/harmonogram')
                                }
                                variant="contained"
                                color="primary"
                                className={classes.thresholdButton}
                            >
                                {text.SET_ATTENDANCE_SCHEDULE}
                            </Button>
                        )}

                        <Button
                            onClick={generateAction}
                            variant="contained"
                            color="primary"
                            className={classes.thresholdButton}
                        >
                            {text.GENERATE_MONTHLY_SUMMARY}
                        </Button>

                        {canGenerateForPreviousMonth && (
                            <Button
                                onClick={async () =>
                                    await getSummaryData(
                                        '/timesheet-monthly-summary/1',
                                        true,
                                        userShop?.id
                                    )
                                }
                                variant="contained"
                                color="primary"
                                className={classes.thresholdButton}
                            >
                                {text.GENERATE_PREVIOUS_MONTH_SUMMARY}
                            </Button>
                        )}

                        <Divider className={classes.createSummaryDivider} />
                    </>
                )}

                <GraphicContext.Provider
                    value={{
                        employeesData,
                        setEmployeesData,
                        periodDialogOpen,
                        setPeriodDialogOpen,
                        startDate,
                        setStartDate,
                        handlePeriodDialogOpen,
                        handlePeriodDialogClose,
                        selectEmployee,
                        selectDate,
                        setUpdate,
                        graphicTypes,
                        editAccess,
                        selectShop,
                        setSelectShop,
                        employeeTimeSheet,
                        setEmployeeTimeSheet,
                        showTimePickers,
                        setShowTimePickers,
                        showWarehouseTimePickers,
                        setShowWarehouseTimePickers,
                    }}
                >
                    {editAccess && <GraphicPeriodDialog />}
                    <GraphicTable />
                </GraphicContext.Provider>
            </ViewContentCard>
            <a style={{ display: 'none' }} ref={ref} />
        </>
    );
};

export default Graphic;
