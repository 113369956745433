import React, { useContext, useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    DialogActions,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import moment from 'moment';
import { AttendanceScheduleContext } from 'pages/GraphicAttendanceSchedule';
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as text from 'constants/strings';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingBottom: theme.spacing(2),
    },
    dialogTitle: {
        paddingBottom: 0,
    },
    quantityContent: {
        paddingTop: '16px',
        marginBottom: '-8px',
    },
}));

const GraphicAttendanceScheduleDialog = () => {
    const {
        startDate,
        scheduleDialogOpen,
        handleScheduleDialogClose,
        setUpdate,
        shopScheduleSheet,
        selectDate,
    } = useContext(AttendanceScheduleContext);

    const dispatch = useDispatch();
    const classes = useStyles();
    const [data, setData] = useState({
        shop: null,
        quantity: 1,
        shopScheduleSheet: null,
        date: null,
    });

    const handleClickSave = async () => {
        const payload = {
            ...data,
            date: moment(data.date).format('YYYY-MM-DD'),
        };

        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('post', '/attendance-schedule', payload);

            dispatch(
                addAlert({ content: 'Poprawnie zapisano', type: 'success' })
            );

            handleScheduleDialogClose();
            setUpdate(Date.now());
        } catch (error) {
            const errorText =
                error.response.data.message || error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const handleQuantityChange = (amount) => {
        setData((prevData) => ({
            ...prevData,
            quantity: Math.max(1, prevData.quantity + amount),
        }));
    };

    useEffect(() => {
        setData({
            ...data,
            date: selectDate || moment(startDate),
            shop: shopScheduleSheet ? shopScheduleSheet.shopId : null,
            shopScheduleSheet: shopScheduleSheet
                ? shopScheduleSheet.scheduleId
                : null,
            quantity: shopScheduleSheet?.scheduledEmployeeQuantity ?? 1,
        });
    }, [selectDate, shopScheduleSheet, startDate]);

    return (
        <Dialog
            maxWidth={false}
            open={scheduleDialogOpen}
            onClose={handleScheduleDialogClose}
            style={{ maxHeight: '90vh' }}
        >
            <DialogTitle className={classes.dialogTitle}>
                {text.SET_ATTENDANCE_SCHEDULE}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs={12} lg={6}>
                        <KeyboardDatePicker
                            disabled={!!selectDate}
                            label="Data"
                            fullWidth
                            variant="inline"
                            format="YYYY-MM-DD"
                            margin="normal"
                            value={data.date}
                            onChange={(date) => setData({ ...data, date })}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel shrink>Ilość osób</InputLabel>
                            <Grid
                                container
                                alignItems="center"
                                className={classes.quantityContent}
                            >
                                <IconButton
                                    onClick={() => handleQuantityChange(-1)}
                                >
                                    <Remove />
                                </IconButton>
                                <span>{data.quantity}</span>
                                <IconButton
                                    onClick={() => handleQuantityChange(1)}
                                >
                                    <Add />
                                </IconButton>
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleScheduleDialogClose} color="secondary">
                    Anuluj
                </Button>
                <Button onClick={handleClickSave} color="primary">
                    Zapisz
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GraphicAttendanceScheduleDialog;
