import {
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import GraphicAttendanceSchedulesBlock from 'components/Graphic/GraphicAttendanceSchedulesBlock';
import moment from 'moment';
import 'moment/locale/pl';
import { AttendanceScheduleContext } from 'pages/GraphicAttendanceSchedule';
import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { StyledTableCell } from 'styles/components';
import { find, map } from 'lodash';

export const ClickedTableCell = styled.div`
    background-color: #e1ebec;
    color: #fff;
    padding: 5px;
    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
    top: 2px;
    position: absolute;
    height: auto;
    right: 1px;
    left: 1px;
    bottom: 0px;
    background-color: ${({ bgcolor }) => bgcolor && bgcolor};
}
`;

const useStyles = makeStyles((theme) => ({
    plusBtn: {
        padding: '0px 0px',
    },
    workerBox: {
        minHeight: 24,
    },
    stickyCol: {
        [theme.breakpoints.down('md')]: {
            position: 'sticky',
            left: '0',
            zIndex: '1',
            background: '#fff',
        },
    },

    weekDay: {
        [theme.breakpoints.down('xs')]: {
            float: 'left',
            width: '240px',
            position: 'sticky',
            left: '0',
            zIndex: '1',
            background: '#fff',
        },
    },

    innerOne: {
        float: 'left',
    },
    innerTwo: {
        float: 'left',
        clear: 'left',
        fontSize: '10px',
    },
    loggedTime: {
        float: 'right',
        marginLeft: '5px',
    },
}));

const GraphicAttendanceScheduleTable = () => {
    const {
        setStartDate,
        startDate,
        handleScheduleDialogOpen,
        shopScheduleData,
    } = useContext(AttendanceScheduleContext);
    const classes = useStyles();

    const getCurrentPeriod = () => {
        const startOfWeek = moment(startDate).startOf('week');
        const endOfWeek = moment(startDate).endOf('week');

        const days = [];
        let day = startOfWeek;

        while (day <= endOfWeek) {
            days.push(day.toDate());
            day = day.clone().add(1, 'd');
        }

        return days;
    };

    const getShopScheduleSheet = (schedule, day) => {
        const attendanceSchedule = find(
            schedule,
            (int) =>
                moment(int.date).format('YYYY-MM-DD') ===
                moment(day).format('YYYY-MM-DD')
        );

        return attendanceSchedule;
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell
                            style={{
                                borderRight: '3px solid rgba(0, 0, 0, 0.27)',
                            }}
                            align="center"
                        />
                        <StyledTableCell
                            align="center"
                            colSpan="7"
                            className={classes.weekDay}
                        >
                            <IconButton
                                onClick={() => {
                                    setStartDate(
                                        moment(startDate).subtract(7, 'd')
                                    );
                                }}
                            >
                                <ArrowBackIosIcon color="primary" />
                            </IconButton>
                            {`${startDate.year()} tydzień ${startDate.week()} `}
                            <IconButton
                                onClick={() => {
                                    setStartDate(moment(startDate).add(7, 'd'));
                                }}
                            >
                                <ArrowForwardIosIcon color="primary" />
                            </IconButton>
                        </StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            style={{
                                borderRight: '3px solid rgba(0, 0, 0, 0.27)',
                                backgroundColor: '#f5f5f5',
                            }}
                        >
                            Sklepy
                        </TableCell>
                        {map(getCurrentPeriod(), (day) => (
                            <StyledTableCell
                                align="center"
                                key={`head-${moment(day).format(
                                    'dddd, DD-MM'
                                )}`}
                            >
                                {moment(day).format('dddd, DD-MM')}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {map(shopScheduleData, (row) => (
                        <TableRow key={row.shopId}>
                            <TableCell
                                style={{
                                    borderRight:
                                        '3px solid rgba(0, 0, 0, 0.27)',
                                }}
                                component="th"
                                scope="row"
                                className={classes.stickyCol}
                            >
                                <Grid
                                    container
                                    justify="space-between"
                                    alignItems="center"
                                    className={classes.workerBox}
                                >
                                    {row.name}
                                </Grid>
                            </TableCell>
                            {map(getCurrentPeriod(), (day) => {
                                const shopScheduleSheet = getShopScheduleSheet(
                                    row.schedules,
                                    moment(day).format('YYYY-MM-DD')
                                );

                                return (
                                    <TableCell
                                        style={{ position: 'relative' }}
                                        align="center"
                                        key={`body-${moment(day).format(
                                            'dddd, MM-DD'
                                        )}`}
                                    >
                                        <ClickedTableCell
                                            onClick={() =>
                                                handleScheduleDialogOpen(
                                                    row.shopId,
                                                    day,
                                                    shopScheduleSheet
                                                )
                                            }
                                            bgcolor={shopScheduleSheet?.scheduleId ? row.color : '#e1ebec'}
                                        >
                                            <GraphicAttendanceSchedulesBlock
                                                shopScheduleSheet={shopScheduleSheet}
                                            />
                                        </ClickedTableCell>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default GraphicAttendanceScheduleTable;
